@import 'npm:normalize.css/normalize.css';

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url(./fonts/Roboto-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url(./fonts/Roboto-Bold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
    url(./fonts/RobotoMono-Bold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url(./fonts/SourceSansPro-Light.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  color: #222;
  font-size: 1em;
  font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

a {
  border-bottom: 1px solid rgba(51, 51, 51, 0.4);
  color: rgb(51, 51, 51);
  padding-bottom: 0.2rem;
  text-decoration: none;
  transition: 150ms color linear, 150ms border-color linear;
}

a:hover {
  border-bottom: 1px solid rgba(40, 130, 230, 0.4);
  color: rgb(40, 130, 230);
}

/* ==========================================================================
   Site styles
   ========================================================================== */

#wrapper {
  max-width: 38rem;
  margin: 2rem auto 0;
  padding: 0 1rem;
}

@media only screen and (min-width: 40em) {
  body {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #wrapper {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-height: 20em) {
  body {
    align-items: flex-start;
  }
}

h1 a {
  background: #333;
  display: inline-block;
  font-family: 'Source Sans Pro', 'Roboto Mono', monospace;
  font-size: 1.2rem;
  font-weight: 300;
  height: 35px;
  line-height: 35px;
  letter-spacing: 3px;
  padding: 0 0.7rem;
  margin: 0;
  text-transform: uppercase;
  vertical-align: top;
}

h1 a,
h1 a:hover {
  color: #fff;
  text-decoration: none;
}

main,
#profiles {
  margin: 2rem 0 0;
}

#profiles ul {
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#profiles li {
  border-bottom: 1px solid #ccc;
  font-size: 0.9rem;
}

#profiles li:last-child {
  border-bottom: none;
}

#profiles a {
  border: none;
  display: block;
  padding: 0.6rem;
}

#profiles a:hover {
  border: none;
}

@media only screen and (min-width: 40em) {
  #profiles li {
    border: none;
    display: inline-block;
  }

  #profiles a {
    border-bottom: 1px solid rgba(51, 51, 51, 0.4);
    display: initial;
    padding: initial;
    padding-bottom: 0.2rem;
  }

  #profiles a:hover {
    border-bottom: 1px solid rgba(40, 130, 230, 0.4);
  }
}

.footer-ceiling {
  border-style: dashed;
  margin: 3em 0 1rem;
  max-width: 6rem;
}

footer {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 3rem;
}

footer nav {
  display: inline;
}

footer a {
  border-bottom: 1px solid rgba(153, 153, 153, 0.4);
  color: #999;
  margin: 0 0.3rem;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

.desktop-only {
  display: none;
}

/* ==========================================================================
   Media Queries for Responsive Design.
   ========================================================================== */

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

@media only screen and (min-width: 40em) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: initial;
  }
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
