html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: local(Roboto), local(Roboto-Regular), url("Roboto-Regular.77718ea6.ttf") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Bold), local(Roboto-Bold), url("Roboto-Bold.aa36be34.ttf") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Mono Bold), local(RobotoMono-Bold), url("RobotoMono-Bold.986cbe2e.ttf") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  src: local(Source Sans Pro Light), local(SourceSansPro-Light), url("SourceSansPro-Light.8fe220db.ttf") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  color: #222;
  font-family: Roboto, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

a {
  color: #333;
  border-bottom: 1px solid #3336;
  padding-bottom: .2rem;
  text-decoration: none;
  transition: color .15s linear, border-color .15s linear;
}

a:hover {
  color: #2882e6;
  border-bottom: 1px solid #2882e666;
}

#wrapper {
  max-width: 38rem;
  margin: 2rem auto 0;
  padding: 0 1rem;
}

@media only screen and (min-width: 40em) {
  body {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  #wrapper {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-height: 20em) {
  body {
    align-items: flex-start;
  }
}

h1 a {
  height: 35px;
  letter-spacing: 3px;
  text-transform: uppercase;
  vertical-align: top;
  background: #333;
  margin: 0;
  padding: 0 .7rem;
  font-family: Source Sans Pro, Roboto Mono, monospace;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 35px;
  display: inline-block;
}

h1 a, h1 a:hover {
  color: #fff;
  text-decoration: none;
}

main, #profiles {
  margin: 2rem 0 0;
}

#profiles ul {
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-weight: 700;
  list-style-type: none;
}

#profiles li {
  border-bottom: 1px solid #ccc;
  font-size: .9rem;
}

#profiles li:last-child {
  border-bottom: none;
}

#profiles a {
  border: none;
  padding: .6rem;
  display: block;
}

#profiles a:hover {
  border: none;
}

@media only screen and (min-width: 40em) {
  #profiles li {
    border: none;
    display: inline-block;
  }

  #profiles a {
    display: initial;
    padding: initial;
    border-bottom: 1px solid #3336;
    padding-bottom: .2rem;
  }

  #profiles a:hover {
    border-bottom: 1px solid #2882e666;
  }
}

.footer-ceiling {
  max-width: 6rem;
  border-style: dashed;
  margin: 3em 0 1rem;
}

footer {
  color: #aaa;
  margin-bottom: 3rem;
  font-size: .8rem;
}

footer nav {
  display: inline;
}

footer a {
  color: #999;
  border-bottom: 1px solid #9996;
  margin: 0 .3rem;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  white-space: inherit;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.desktop-only {
  display: none;
}

@media only screen and (min-width: 40em) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: initial;
  }
}

@media print {
  *, :before, :after {
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.cb182cb4.css.map */
